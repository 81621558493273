import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Field } from 'react-final-form';
import { makeStyles } from '@material-ui/styles';
import { renderer } from '../../renderer';
import { fieldsSchema } from './form-schema';
import { AdditionalFeatures } from '../AdditionalFeatures';
import { DistancePackages } from '../DistancePackages';
import { ManagementCalendar } from '../ManagementCalendar/ManagementCalendar';
import { CarLabels } from '../CarLabels';

const useStyles = makeStyles(theme => ({
  outlined: {
    padding: '10.5px 14px',
  },
  margin: {
    margin: '8px 0 4px',
  },
}));

export const OtherInformation = props => {
  const classes = useStyles();

  return (
    <Grid container justify="space-between" alignItems="flex-start">
      <CarLabels {...props} />

      <h3 className="title" style={{ width: '100%', margin: '30px 0 20px' }}>
        Доп. информация о машине
      </h3>
      {fieldsSchema.map(field => (
        <Grid container xs={4} justify="center" wrap="wrap" key={field.name} item>
          <Field name={field.name}>
            {({ input }) => {
              return renderer(field, input, classes);
            }}
          </Field>
        </Grid>
      ))}
      <ManagementCalendar {...props} />
      <AdditionalFeatures {...props} />
      <DistancePackages {...props} />
    </Grid>
  );
};
