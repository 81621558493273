import * as React from 'react';
import { cloneElement } from 'react';
import { List, ListActions, Button, TopToolbar } from 'react-admin';
import IconEvent from '@material-ui/icons/Event';

import { CascoFeeUploader } from './CascoFeeUploader';

export const Actions = props => (
  <TopToolbar style={{ paddingTop: 0 }}>
    {cloneElement(props.filters, { context: 'button' })}
    <CascoFeeUploader />
  </TopToolbar>
);
